import FeaturesCarousel from "./FeaturesCarousel";

export default function Features() {
  
  return (
    <section className="features-section">      
        <div className="carousel-titles">
          <h3>Unsere Features.</h3>
          <h3>Das zeichnet uns aus.</h3>
        </div>
        <FeaturesCarousel />
    </section>
  );
}
