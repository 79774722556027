import { useState } from 'react';

export const Kontakt = () => {

    const [formData, setFormData] = useState({
        name: '',
        role: '',
        email: '',
        phone: '',
        message: '',
        consent: false
      });
    
      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const val = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: val });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log(formData);
      };
    
      return (
        <div className="contact-container">
            <h1>Kontakt</h1>
            <div className='contact-details'>
                <h2>E-Mail</h2>
                <span>info@stu-studios.de</span>
                <h2>Telefon</h2>
                <span>+49 176 20291843</span>
            </div>
            <h1>Schreib uns gerne.</h1>
        <form className="contact-form" onSubmit={handleSubmit}>
            <div className='contact-form-box'>
                <div>
                <label htmlFor="name">Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-input"
                />
                </div>
                <div>
                    <label htmlFor="role">Deine Rolle</label>
                    <select
                        id="role"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        className="form-input"
                    >
                    <option value="TonStudio">Tonstudio/Musikstudio Host</option>
                    <option value="TonStudio">Fotostudio Host</option>
                    <option value="TonStudio">Videostudio Host</option>
                    <option value="TonStudio">Mieter/Gast</option>
                    <option value="TonStudio">Produzent</option>
                    <option value="TonStudio">Fotograf</option>
                    <option value="TonStudio">Videograf</option>
                    </select>
                </div>
            </div>
            <div className='contact-form-box'>
                <div>
                    <label htmlFor="email">E-Mail Adresse</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-input"
                    />
                </div>
                <div>
                    <label htmlFor="phone">Telefonnummer (optional)</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="form-input"
                    />
                </div>
            </div>
            <div className='contact-form-box'>
                <div>
                <label htmlFor="message">Wie können wir dir helfen?</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="form-input"
                ></textarea>
                </div>
            </div>
            <div className="consent">
                <input
                type="checkbox"
                id="consent"
                name="consent"
                checked={formData.consent}
                onChange={handleChange}
                className="checkbox-input"
                />
                <label htmlFor="consent">
                    Ich bin damit einverstanden, dass diese Website meine personenbezogenen Daten speichert und verarbeitet.
                </label>
            </div>
            <button type="submit" className="submit-button">
                Senden
            </button>
            </form>
        </div>
      );
  };
  
export default Kontakt;

  