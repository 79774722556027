import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from "react-scroll";

const NavigationHamburger = ({ menuOpen, onClose }) => {

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [menuOpen]);

  return (
    <div className={`hamburger-menu-container ${menuOpen ? 'open' : 'closed'}`}>
      <header className="open-tablet-header">
        <button onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="22" viewBox="0 0 34 22" fill="none">
            <line x1="26.1054" y1="19.6759" x2="8.42772" y2="1.99819" stroke="white" strokeWidth="2"/>
            <line x1="8.40544" y1="19.684" x2="26.0831" y2="2.00634" stroke="white" strokeWidth="2"/>
          </svg>
        </button>
      </header>
      <div>
        <nav className="header-navigation-hamburger">
          <Link delay={800} to="produkt" onClick={onClose} smooth={true} duration={1000} className="navigation-links">
            Produkt
          </Link>
          <Link delay={800} to="about" onClick={onClose} smooth={true} duration={1000} className="navigation-links">
            Über Uns
          </Link>
          <Link delay={800} to="questions" onClick={onClose} smooth={true} duration={2500} className="navigation-links">
            FAQ
          </Link>
          <NavLink to="/kontakt" onClick={onClose} className='navigation-links' target="_blank">Kontakt</NavLink>
        </nav>
      </div>
    </div>
  );
};

export default NavigationHamburger;
