import stuStory from "../images/about/Stu_Story.webp"
import stuMission from "../images/about/Stu_mission.webp"

const About = () => {

  return (
    <div id="about" className="about-section">
        <div className="about-container">
            <div className="about-row">
                <div className="about-box">
                    <p>Unsere Geschichte</p>
                    <h4>Stu: Entstanden aus einem Problem</h4>
                    <span>Einer unserer Gründer suchte jahrelang in einer fremden Stadt nach dem idealen Studio für seinen musikalischen Traum. Endlose Telefonate, kurzfristige Absagen, mangelhafte Service-Qualität. Je mehr er suchte, desto größer wurde die Frustration.</span>
                    <span>Dem kreativen Markt fehlte es an Transparenz, Marktüberblick und einer zentralen Anlaufstelle für Suchende. Doch aus der Verzweiflung entstand eine Vision: Den Markt für Kreativ-Studios von Grund auf neu zu gestalten.</span>
                    <span>Das Ergebnis? Stu - die Plattform, die Vision mit Wirklichkeit vereint. Hier finden Kreative endlich, wonach sie lange gesucht haben: Übersicht, Klarheit und den direkten Weg zum perfekten Studio-Erlebnis.</span>
                    <span className="about-infos-mobile">
                    Einer unserer Gründer, Haci, suchte jahrelang in einer fremden Stadt nach einem passenden Studio für seinen musikalischen Traum. 
                    In einer Branche, in der nicht nur Transparenz und Überblick über Angebote und Preise, sondern auch klare Anlaufstellen für Suchende fehlten, 
                    kam die Idee auf, den Markt für kreative Studios zu revolutionieren. 
                    <br />
                    Das Ergebnis: Stu.
                    </span>
                </div>
                <div className="about-image-container">
                    <img alt="Stu offices" loading="lazy" src={stuStory} />
                </div>
            </div>
            <div className="about-row">
                <div className="about-image-container">
                    <img alt="Stu photographer's studio" loading="lazy" src={stuMission} />
                </div>
                <div className="about-box">
                    <p>Unsere Mission</p>
                    <h4>Kreative & Studios verbinden</h4>
                    <span>Stu verbindet Künstler und Studios auf der Suche nach Inspiration. Unsere Mission ist es, jedem die Möglichkeit zu geben, kreativ zu werden und gleichzeitig Studioeigentümern die Plattform zu bieten, ihre Studios optimal zu nutzen und finanziell zu profitieren. So schaffen wir eine lebendige Gemeinschaft für Kreative, überall.</span>
                </div>
            </div>
        </div>
    </div>
  );
};

export default About;