import ImagesCarouselAnimation from "./ImagesCarouselAnimation"

const SecondSection = () => {

  return (
    <div className="second-section-container">
      <div className="second-section-infos-container">
        <h2>Studios für Musik, Foto und Video.</h2>
        <div className="second-section-extra-infos">
          <span>Neue Fashion-Kollektion? Entdecke einzigartige Kulissen.</span>
          <span>Du hast ein kleines Homestudio? Mache Geld daraus.</span>
          <span>All das und vieles mehr kann Stu.</span>
        </div>
      </div>
      <ImagesCarouselAnimation />
      <div className="long-line"></div>
    </div>
  );
};

export default SecondSection;