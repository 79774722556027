import { useState } from "react";
import { NavLink } from "react-router-dom";

import NavBar from "./NavBar";
import firstWallpaper from "../images/first-page-image.webp";
import NavigationHamburger from "./NavigationHamburger";

const FirstScreen =({ className })  => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = (isOpen) => {
    setMenuOpen(isOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className={`first-screen-container ${className}`}>
      <div className="first-screen-wallpaper-container">
        <img className="first-screen-wallpaper" src={firstWallpaper} alt="First Screen" />
      </div>
      <NavBar onToggleMenu={handleToggleMenu} />
      <NavigationHamburger menuOpen={menuOpen} onClose={handleCloseMenu} />
      <div className="first-section-infos-container">
        <div className="main-infos-container">
          <h1>Deine Idee.</h1>
          <h1>Dein Studio.</h1>
        </div>
        <div className="small-infos-section">
          <span>Miete und vermiete ganz einfach dein Studio im Bereich Musik, Foto und Video.</span>
          <span>Die Plattform, die Kreative und Studios miteinander verbindet.</span>
        </div>
       <NavLink to="/kontakt" className="contact-button" target="_blank">Kontaktiere uns</NavLink>
      </div>
    </div>
  );
};

export default FirstScreen;