import markImage from "../images/gesichter/mark.webp"
import haciImage from "../images/gesichter/haci.webp"
import lucaImage from "../images/gesichter/luca.webp"

import markMobileImage from "../images/gesichter/Gesichter Fotos/mark_klein.webp"
import haciMobileImage from "../images/gesichter/Gesichter Fotos/haci_Klein.webp"
import lucaMobileImage from "../images/gesichter/Gesichter Fotos/luca_klein.webp"

const Founders = () => {

  return (
    <section className='founders-section-container'>
        <div className="founders-line"></div>
        <h1 className="founders-title">Das sind unsere Gründer.</h1>
        <h1 className="phone-founders">Unsere Gründer.</h1>
        <div className="founders-container">
            <div className="founder-box">
                <div className="founders-image-container">
                    <img className="images-founders" loading="lazy" src={markImage} alt="Mark Darwich Technische Entwicklung" />
                    <img className="mobile-images-founders" loading="lazy" src={markMobileImage} alt="Mark Darwich Technische Entwicklung" />
                </div>
                <div className="founder-details">
                    <h3 className="founders-names">Mark Darwich</h3>
                    <h3 className="grey-color">Technische Entwicklung</h3>
                </div>
            </div>
            <div className="founder-box">
                <div className="founders-image-container">
                    <img className="images-founders" loading="lazy" src={haciImage} alt="Haci Erkan Unternehmensen Twicklung" />
                    <img className="mobile-images-founders" loading="lazy" src={haciMobileImage} alt="Haci Erkan Unternehmensen Twicklung" />
                </div>
                <div className="founder-details">
                    <h3 className="founders-names">Haci Erkan</h3>
                    <h3 className="grey-color">Unternehmens Entwicklung</h3>
                </div>
            </div>
            <div className="founder-box">
                <div className="founders-image-container">
                    <img className="images-founders" loading="lazy" src={lucaImage} alt="Mark Darwich Technische Entwicklung" />
                    <img className="mobile-images-founders" loading="lazy" src={lucaMobileImage} alt="Mark Darwich Technische Entwicklung" />
                </div>
                <div className="founder-details">
                    <h3 className="founders-names">Luca Ferreira</h3>
                    <h3 className="grey-color">Digitales Produktdesign</h3>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Founders;
