import { useEffect, useState, useRef } from 'react';

import image1 from "../images/so-funktioniert-stu/Profil_funktion.webp";
import image2 from "../images/so-funktioniert-stu/Website_Funktion.webp";
import image3 from "../images/so-funktioniert-stu/bezahlt.webp";
import targetImg from "../images/so-funktioniert-stu/Icons_So_Funktioniert_Stu/1.0Finde_Studio.svg";
import laptopImg from "../images/so-funktioniert-stu/Icons_So_Funktioniert_Stu/3.0Kreative_engagieren.svg";
import calendarImg from "../images/so-funktioniert-stu/Icons_So_Funktioniert_Stu/4.0Buchung.svg";
import tabletImage1 from "../images/so-funktioniert-stu/tablet/Bild 1.jpg";
import tabletImage2 from "../images/so-funktioniert-stu/tablet/Bild 2.png";
import tabletImage3 from "../images/so-funktioniert-stu/tablet/Bild 3.png";

const desktopImages = [
    { image: image1, alt: 'Image Profil' },
    { image: image2, alt: 'Website Function' },
    { image: image3, alt: 'Image Payment' },
];

const tabletImages = [
    { image: tabletImage1, alt: 'Tablet Image 1' },
    { image: tabletImage2, alt: 'Tablet Image 2' },
    { image: tabletImage3, alt: 'Tablet Image 3' },
];

const SoFunktioniertStu = () => {
    const [images, setImages] = useState(desktopImages);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentBoxIndex, setCurrentBoxIndex] = useState(0);
    const boxesRef = useRef([]);
    const [overlayTop, setOverlayTop] = useState(0);
    const [boxHeight, setBoxHeight] = useState(0);

    const updateImages = () => {
        if (window.innerWidth <= 1200) {
            setImages(tabletImages);
        } else {
            setImages(desktopImages);
        }
    };

    useEffect(() => {
        updateImages();
        window.addEventListener('resize', updateImages);
        return () => window.removeEventListener('resize', updateImages);
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 430) {
            return;
        }

        const intervalDuration = currentImageIndex === images.length - 1 ? 5000 : 3000;

        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex < images.length - 1 ? prevIndex + 1 : 0
            );
            setCurrentBoxIndex((prevIndex) =>
                prevIndex < 2 ? prevIndex + 1 : 0
            );
        }, intervalDuration);

        return () => clearInterval(interval);
    }, [currentImageIndex, images.length]);

    useEffect(() => {
        if (boxesRef.current[currentBoxIndex]) {
            const box = boxesRef.current[currentBoxIndex];
            const { height } = box.getBoundingClientRect();
            const offsetTop = box.offsetTop;
            setOverlayTop(offsetTop);
            setBoxHeight(height);
        }
    }, [currentBoxIndex]);

    const handleBoxHover = (index) => {
        setCurrentBoxIndex(index);
        setCurrentImageIndex(index);
    };

    return (
        <div id="produkt" className="stu-work-session">
            <div className="slideshow">
                {images.map((image, index) => (
                    <img
                        loading="lazy"
                        key={index}
                        src={image.image}
                        className={index === currentImageIndex ? "slideshow active" : ''}
                        alt={image.alt}
                    />
                ))}
            </div>
            <div className='stu-work-infos'>
                <h3>So funktioniert Stu.</h3>
                <div className="boxes-container">
                    <div
                        className="box"
                        ref={el => boxesRef.current[0] = el}
                        onMouseEnter={() => handleBoxHover(0)}
                    >
                        <img src={targetImg} alt='Target-Svg'/>
                        <div className='box-infos'>
                            <h4>Finde das perfekte Studio.</h4>
                            <span>
                                Finde den perfekten Ort für Musik,
                                Foto und Film in deiner Nähe -
                                nutze unsere Plattform mit Filtern und Kategorien.
                            </span>
                        </div>
                    </div>
                    <div className="separator-line"></div>
                    <div
                        className="box"
                        ref={el => boxesRef.current[1] = el}
                        onMouseEnter={() => handleBoxHover(1)}
                    >
                        <img src={laptopImg} alt='Laptop-Svg'/>
                        <div className='box-infos'>
                            <h4 className='hidden-title'>Engagiere Kreative für dein Projekt.</h4>
                            <h4 className='hidden-phone-title'>Beauftrage Kreative.</h4>
                            <span>
                                Entdecke talentierte Kreative und
                                wähle den perfekten Partner für deine Vision aus.
                            </span>
                        </div>
                    </div>
                    <div className="separator-line"></div>
                    <div
                        className="box"
                        ref={el => boxesRef.current[2] = el}
                        onMouseEnter={() => handleBoxHover(2)}
                    >
                        <img style={{ width: "40px" }} src={calendarImg} alt='Calendar-Svg' />
                        <div className='box-infos'>
                            <h4>Buche ganz einfach.</h4>
                            <span>
                                Mit wenigen Klicks reservierst du dein Studio und buchst Kreative,
                                sicher und schnell bezahlt.
                            </span>
                        </div>
                    </div>
                    <div
                        className="overlay"
                        style={{ top: `${overlayTop}px`, height: `${boxHeight}px` }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SoFunktioniertStu;