import React, { useState, useEffect } from 'react';
import FirstScreen from '../components/FirstScreen';
import SecondSection from '../components/SecondSection';
import SoFunktioniertStu from "../components/SoFunktioniertStu"
import About from '../components/About';
import BetaVersion from "../components/BetaVersion"
import QuestionsAnswers from "../components/QuestionsAnswers"
import Founders from "../components/Founders"
import Footer from "../components/Footer"
import Features from "../components/Features"
import { Helmet } from 'react-helmet'

export const Home = () => {
  const [isFirstScreenVisible, setIsFirstScreenVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      setIsFirstScreenVisible(scrollPosition < windowHeight * 1.5);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <Helmet>
        <title>Stu | Kreative Studios im Bereich Musik, Foto und Video</title>
        <meta name="description" content="Buche und vermiete Studios für Musik, Foto und Video. Finde schnell passende Räume oder vermarkte dein Studio effektiv." />
      </Helmet>
      <div className="home-container">
        <FirstScreen className={isFirstScreenVisible ? '' : 'first-screen-hidden'} />
        <div style={{ 
          position: 'relative', 
          zIndex: 2, 
          backgroundColor: '#F4F4F4', 
          marginTop: '100vh',
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem"
        }}>
          <SecondSection />
          <SoFunktioniertStu />
          <Features />
          <About />
          <BetaVersion />
          <QuestionsAnswers />
          <Founders />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;