// import spaceImage from "../images/features/1/1.webp"
// import callendarImage from "../images/features/2/2.webp"
// import userImage from "../images/features/3/3.webp"
// import smartImage from "../images/features/4/4.webp"

// import smallSpace from "../images/features/1/Vermarktung.svg"
// import smallCallendar from "../images/features/2/Planung.svg"
// import smallUser from "../images/features/3/3.svg"
// import smallSmart from "../images/features/4/Suche.svg"

import firstPictureDesktop from "../images/StuImages(DesktopTablet)/Vermarktung.png"
import secondPictureDesktop from "../images/StuImages(DesktopTablet)/Planungstool.png"
import thirdPictureDesktop from "../images/StuImages(DesktopTablet)/Individuelle Künstlerprofile.png"
import forthPictureDesktop from "../images/StuImages(DesktopTablet)/Group 50.png"

import firstPicturePhone from "../images/StuImages(Mobile)/effiziente Vermarktung.png"
import secondPicturePhone from "../images/StuImages(Mobile)/Planung.png"
import thirdPicturePhone from "../images/StuImages(Mobile)/Künstlerprofile.png"
import forthPicturePhone from "../images/StuImages(Mobile)/Group 136.png"

const desktopPictures = [
  { id: 1, src: firstPictureDesktop },
  { id: 2, src: secondPictureDesktop },
  { id: 3, src: thirdPictureDesktop },
  { id: 4, src: forthPictureDesktop }
];

const mobilePictures = [
  { id: 1, src: firstPicturePhone },
  { id: 2, src: secondPicturePhone },
  { id: 3, src: thirdPicturePhone },
  { id: 4, src: forthPicturePhone }
];


export default function FeaturesCarousel() {
  
  return (
          <>
            <div className="features-carousel-container">
                {desktopPictures.map((picture) => (
                    <img src={picture.src} alt={`Feature ${picture.id}`} key={picture.id} />
                ))}
            </div>

            <div className="features-carousel-container-phone">
                {mobilePictures.map((picture) => (
                    <img src={picture.src} alt={`Feature ${picture.id}`} key={picture.id} />
                ))}
            </div>
          </>
  );
}

// const cards = [
//   {
//     src: spaceImage,
//     smallSrc: smallSpace,
//     alt: "Spaces to rend",
//     color: "#101010",
//     lettersColor: "#A2A2AA",
//     titleColor: "#FFF",
//     title: "Effiziente Vermarktung für Anbieter", 
//     parag: "Stu maximiert Studio-Vermarktung durch klare Angebote, attraktive Portfolios und präzise Produktdetails - effizient beworben und einfach gefunden",
//     id: 1,
//     paragraphColor: "#A2A2AA",
//   },
//   {
//     src: callendarImage,
//     smallSrc: smallCallendar,
//     alt: "Choose your Dates",
//     color: "#EBEBF0",
//     lettersColor: "#101010",
//     title: "Planungs- und Management-Tool", 
//     parag: "Einfache Einnahmenverfolgung, effiziente Kalenderplanung und Anbieter-Support für eine reibungslose Studiovermietung.",
//     id: 2,
//   },
//   {
//     src: userImage,
//     smallSrc: smallUser,
//     alt: "Create your Profile",
//     color: "#101010",
//     lettersColor: "#6E6E73",
//     titleColor: "#FFF",
//     title: "Individuelle Künstlerprofile", 
//     paragraphColor: "#A2A2AA",
//     parag: "Stu-Künstlerprofile bieten Anbietern eine individuelle Studio-Präsentation mit detaillierten Angaben zu Genre, Ausstattung und Portfolio.",
//     id: 3,
//   },
//   {
//     src: smartImage,
//     smallSrc: smallSmart,
//     alt: "Smart Search",
//     color: "#EBEBF0",
//     lettersColor: "#101010",
//     title: "Intelligente Suche", 
//     parag: "Entdecke Studios mühelos mit unserer intelligenten Suche – genau nach deinen Bedürfnissen, sei es für Musik, Fotos oder Videos.",
//     id: 4,
//   },
// ];

// const Card = ({ card }) => {
//   return (
//     <div className="marketing-card" style={{ backgroundColor: card.color, color: card.lettersColor }}>
//           <div className="card-container">
//             <div className="card-container-details">
//               <img loading="lazy" className="small-image" src={card.smallSrc} alt={card.alt} />
//               <h3 style={{ color: card.titleColor }} >{card.title}</h3>
//               <span style={{ color: card.paragraphColor }}>{card.parag}</span>
//             </div>
//             <div className="card-container-image">
//               <img className="large-image" src={card.src} alt={card.alt} />
//             </div>
//           </div>
//     </div>
//   );
// };